<template lang="pug">
#content-create-section
  TextInput.px-4.py-2(
    label="Name"
    placeholder="Section Name"
    v-model:value="section_name"
    v-bind:editable="false"
  )
  .flex.flex-col.px-4
    label.text-gray-600.font-bold Playlist
    .flex.flex-row.flex-wrap
      PlaylistBox(
        v-for="play in playlist"
        :key="play.row.key"
        v-bind="play"
      )
  .px-4.pt-4.pb-4
    ClickInput(
      text="EDIT"
      @callback="move_to_edit"
    )
</template>

<script>
import TextInput from "../components/input/TextInput";
import SubmitInput from "../components/input/SubmitInput";
import apiUrl from "../apiUrl";
import Check from "../components/icon/Check";
import PlaylistBox from "../components/PlaylistBox";
import ClickInput from "../components/input/ClickInput";

export default {
  name: "CreateSection",
  data() {
    return {
      section_name: "",
      apiUrl,
      playlist: [],
    };
  },
  created() {
    this.axios.get(apiUrl.section_one(this.row_id)).then(
      ({
        data: {
          data: {
            section: { section_name, body },
            section_body,
          },
        },
      }) => {
        this.playlist = body.map((key) => {
          return {
            row: section_body[key],
            selected: false,
          };
        });
        this.section_name = section_name;
      }
    );
  },
  computed: {
    row_id() {
      return this.$route.params.row_id;
    },
  },
  methods: {
    move_to_edit() {
      this.$router.push({
        name: "EditSection",
        params: {
          row_id: this.row_id,
        },
      });
    },
  },
  components: {
    ClickInput,
    PlaylistBox,
    Check,
    SubmitInput,
    TextInput,
  },
};
</script>
