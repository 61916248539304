<template lang="pug">
Base(path="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z")
</template>

<script>
import Base from "./Base";

export default {
  components: {
    Base,
  },
};
</script>
