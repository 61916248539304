<template lang="pug">
Base(:path="path")
</template>

<script>
import Base from "./Base";

export default {
  name: "Plus",
  props: {
    width: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    path() {
      let s = `0 ${10 - this.width}`;
      let w = this.width * 2;
      let l = 10 - this.width;

      return `M${s} v${w} h${l} v${l} h${w} v${-l} h${l} v${-w} h${-l} v${-l} h${-w} v${l} Z`;
    },
  },
  created() {
    console.log(this.width);
  },
  components: {
    Base,
  },
};
</script>
