<template lang="pug">
.flex.flex-col
  label.text-gray-600.font-bold {{ label }}
  input.px-4.py-2.rounded-xl(
    type="text"
    :placeholder="placeholder"
    @change="changeValue"
    :value="value"
  :disabled="!editable"
  )
</template>

<script>
export default {
  name: "TextInput",
  props: {
    placeholder: {
      type: String,
      default: "placeholder",
    },
    label: {
      type: String,
      default: "Label",
    },
    value: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:value"],
  methods: {
    changeValue(e) {
      if (this.editable) {
        this.$emit("update:value", e.target.value);
      }
    },
  },
};
</script>

<style scoped></style>
