import Home from "../views/Home";
import { createRouter, createWebHistory } from "vue-router";
import CreateSection from "../views/CreateSection";
import ViewSection from "../views/ViewSection";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/create",
    name: "CreateSection",
    component: CreateSection,
  },
  {
    path: "/view/:row_id",
    name: "ViewSection",
    component: ViewSection,
  },
  {
    path: "/edit/:row_id",
    name: "EditSection",
    component: CreateSection,
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
});

export default router;
