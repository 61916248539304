<template lang="pug">
button.w-full.bg-primary-300.p-2.rounded-xl.text-white.font-bold(@click="trigger") {{ text }}
</template>

<script>
export default {
  name: "ClickInput",
  props: {
    text: {
      type: String,
      default: "Click",
    },
  },
  emits: ["callback"],
  methods: {
    trigger() {
      this.$emit("callback");
    },
  },
};
</script>
