<template lang="pug">
Base(path="M18.043,2.676c-0.244-0.246-0.642-0.246-0.884,0L7.842,11.950  c-0.244,0.247-0.642,0.247-0.885,0L2.874,7.793C2.753,7.669,2.595,7.608,2.436,7.607C2.275,7.607,2.112,7.668,1.989,7.793  l-1.804,1.622C0.065,9.537,0,9.692,0,9.853c0,0.162,0.065,0.331,0.186,0.453l4.117,4.320c0.243,0.247,0.642,0.647,0.885,0.892  l1.770,1.784c0.243,0.244,0.641,0.244,0.885,0l11.972-11.950c0.244-0.245,0.244-0.648,0-0.892L18.043,2.676z")
</template>

<script>
import Base from "./Base";

export default {
  components: {
    Base,
  },
};
</script>
