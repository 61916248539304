<template lang="pug">
.p-4
  .font-bold.text-primary-900 {{ section_name }}
  .py-1.flex.flex-row.flex-wrap
    PlaylistBox(
      v-for="play in children"
      :key="play.key"
      :row="play"
    )
    .w-8.h-9.flex.justify-center.items-center
      router-link.w-6.h-6.flex.justify-center.items-center.rounded-full.bg-primary-300(
        :to="{ name: 'ViewSection', params: { row_id: key_id } }"
      )
        Plus(:width="2")
</template>

<script>
import Plus from "./icon/Plus";
import PlaylistBox from "./PlaylistBox";

export default {
  name: "SmallSection",
  components: {
    PlaylistBox,
    Plus,
  },
  props: {
    section_name: String,
    children: Array,
    key_id: Number,
  },
};
</script>
