const BASE_API_URL = process.env.VUE_APP_BASE_API_URL;
const LOGIN_ORIGIN = process.env.VUE_APP_LOGIN_ORIGIN;
const LOGIN_API_URL = process.env.VUE_APP_LOGIN_API_URL;

const apiUrl = {
  ping: `${BASE_API_URL}/ping`,
  whoami: `${LOGIN_ORIGIN}${LOGIN_API_URL}/whoami`,
  login: `${LOGIN_ORIGIN}`,
  section: `${BASE_API_URL}/section`,
  section_body: `${BASE_API_URL}/section-body`,
  section_one: (key_id) => `${BASE_API_URL}/section/${key_id}`,
};

export default apiUrl;
