<template lang="pug">
Base(path="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z")
</template>

<script>
import Base from "./Base";

export default {
  components: {
    Base,
  },
};
</script>
