<template lang="pug">
svg.fill-current.h-4.w-4(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20")
  path(:d="path")
</template>

<script>
// ICON flaticon

export default {
  props: {
    path: String,
  },
};
</script>
