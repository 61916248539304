<template lang="pug">
#content-create-section
  TextInput.px-4.py-2(
    label="Name"
    placeholder="Section Name"
    v-model:value="section_name"
  )
  .flex.flex-col.px-4.pt-2
    label.text-gray-600.font-bold Playlist
    .flex.flex-row.flex-wrap
      PlaylistBox(
        v-for="play in playlist_wrap"
        :key="play.row.key"
        v-bind="play"
        @click="toggle_playlist(play.row)"
      )
  .px-4.pt-2
    span.font-bold.text-primary-700 {{ section_name }}
    span {{ selected_playlist_many }}
    span.pl-1 playlist selected
  .px-4.pt-2.pb-4
    SubmitInput(
      :url="submit_url"
      :data="{section_name, body}"
      text="Save"
    )
</template>

<script>
import TextInput from "../components/input/TextInput";
import SubmitInput from "../components/input/SubmitInput";
import apiUrl from "../apiUrl";
import Check from "../components/icon/Check";
import PlaylistBox from "../components/PlaylistBox";

export default {
  name: "CreateSection",
  data() {
    return {
      section_name: "",
      apiUrl,
      playlist: [],
      body: [],
    };
  },
  created() {
    if (this.row_id) {
      this.axios.get(apiUrl.section_one(this.row_id)).then(
        ({
          data: {
            data: {
              section: { body, section_name },
            },
          },
        }) => {
          this.body = body;
          this.section_name = section_name;
        }
      );
    }
    this.axios.post(apiUrl.section_body).then(({ data: { data } }) => {
      this.playlist = data;
    });
  },
  methods: {
    toggle_playlist(p) {
      let index = this.body.findIndex((e) => e === p.key);
      if (index >= 0) {
        this.body.splice(index, 1);
      } else {
        this.body.push(p.key);
      }
    },
  },
  computed: {
    row_id() {
      return this.$route.params.row_id;
    },
    playlist_wrap() {
      let select_obj = Object.fromEntries(this.body.map((bs) => [bs, true]));
      return this.playlist.map((row) => {
        return {
          selected: select_obj[row.key] || false,
          row,
        };
      });
    },
    selected_playlist_many() {
      return this.body.length;
    },
    submit_url() {
      if (this.row_id) {
        return apiUrl.section_one(this.row_id);
      } else {
        return apiUrl.section;
      }
    },
  },
  components: {
    PlaylistBox,
    Check,
    SubmitInput,
    TextInput,
  },
};
</script>
