<template lang="pug">
.flex.justify-center.p-4
  Loading
</template>

<script>
import Loading from "../icon/Loading";

export default {
  name: "LoadingBlock",
  components: { Loading },
};
</script>
