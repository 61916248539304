<template lang="pug">
button.w-full.bg-primary-300.p-2.rounded-xl.text-white.font-bold(@click="trigger")
  Loading(v-if="loading")
  span(v-else="") {{ text }}
</template>

<script>
import Loading from "../icon/Loading";
export default {
  name: "ButtonInput",
  components: {
    Loading,
  },
  emits: ["callback"],
  data() {
    return {
      loading: false,
    };
  },
  props: {
    url: String,
    data: Object,
    text: {
      type: String,
      default: "Submit",
    },
  },
  methods: {
    trigger() {
      this.axios.post(this.url, this.data);
    },
  },
};
</script>
