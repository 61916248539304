<template lang="pug">
.p-4
  a.block.rounded-2xl.shadow-inner.p-4.bg-primary-200.text-center.text-primary-700(
    :href="api_url.login"
  ) Click Here To Login
</template>

<script>
import apiUrl from "../apiUrl";

export default {
  name: "Unknown",
  data() {
    return {
      api_url: apiUrl,
    };
  },
};
</script>
