<template lang="pug">
.w-28.mx-1
  .w-full.h-21.mt-1.relative.border-primary-900.border-solid.box-border.bg-center(
    :style="{ backgroundImage: 'url(' + row.info.snippet.thumbnails.default.url + ')' }"
    :class="{ 'border-3': selected }"
    @click="$emit('click')"
  )
    .w-6.h-6.rounded-full.bg-primary-800.flex.justify-center.items-center.absolute.right-1.bottom-1(
      v-if="selected"
    )
      Check.text-primary-100
  .text-xs.text-primary-800.w-full.truncate {{ row.info.snippet.title }}
</template>

<script>
import Check from "./icon/Check";
export default {
  name: "PlaylistBox",
  props: {
    row: Object,
    selected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  components: {
    Check,
  },
};
</script>
