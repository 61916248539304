<template lang="pug">
.bg-primary-100.flex.flex-col.app
  header.sticky.top-0.h-12.flex.bg-primary-500.text-white.z-10
    div.flex-grow.flex.justify-center.items-center.text-3xl
      div Drawer
    div.absolute.flex.inset-y-0.left-0
      button.flex.px-6.items-center.text-white-500(
        type="button"
        class="md:hidden focus:outline-none focus:text-gray-700"
        :class="{ hidden: menu_visible, block: !menu_visible }"
        @click="open_menu"
      )
        Bars
      button.flex.px-6.items-center.text-white-500(
        type="button"
        class="md:hidden focus:outline-none focus:text-gray-700"
        :class="{ hidden: !menu_visible, block: menu_visible }"
        @click="close_menu"
      )
        Times
    div.absolute.inset-y-0.right-0.flex.items-center(v-if="profile_loading")
      template(v-if="profile_known")
        div.mr-4 {{ profile.name }}
        div.w-12.h-12.bg-center.bg-cover(:style="profile_style")
      div.mr-4(v-else="")
        a(:href="api_url.login") LOGIN
  .flex.flex-grow(class="md:pl-40")
    nav.z-20.fixed.inset-x-0.inset-y-0.flex.mt-12(
      class="md:flex md:mt-0 md:top-12 md:bottom-12 md:w-40"
      :class="nav_class"
    )
      .flex.flex-col.py-2.bg-white.shadow-lg.w-40
        router-link.px-8.py-2(
          v-for="nav in nav_list"
          :to="{ name: nav.name }"
          :key="nav.name"
          class="hover:bg-primary-200"
        ) {{ nav.text }}
      .opacity-25.bg-black.flex-grow(
        class="md:hidden"
        @click="close_menu"
      )
    LoadingBlock.flex-grow(v-if="!profile_loading")
    Unknown.flex-grow(v-else-if="!profile_known")
    router-view.flex-grow(v-else="")
  footer.bg-primary-200.sticky.inset-x-0.bottom-0.h-12.p-2.text-center.text-2xs
    div.text-primary-300 무심코 누른 아이콘에 생각난 너
    div.text-primary-400
      a(href="https://github.com/zhuny/youtube-drawer")
        span made by
        span &nbsp;
        span.font-bold zhuny
</template>

<script>
import nav from "./router/nav";
import Bars from "./components/icon/Bars";
import Times from "./components/icon/Times";
import apiUrl from "./apiUrl";
import LoadingBlock from "./components/block/LoadingBlock";
import Unknown from "./views/Unknown";

export default {
  data() {
    return {
      api_url: apiUrl,
      profile: {},
      profile_loading: false,
      profile_known: false,
      nav_list: nav,
      menu_visible: false, // for mobile
    };
  },
  created() {
    if (this.$route.query.path) {
      this.$router.push("/" + this.$route.query.path);
    }
    this.axios.get(apiUrl.whoami).then(({ data: { known, user } }) => {
      this.profile_loading = true;
      if (known) {
        this.profile = user;
        this.profile_known = true;
      }
    });
  },
  computed: {
    nav_class() {
      return {
        hidden: !this.menu_visible,
      };
    },
    profile_style() {
      if (this.profile.picture) {
        return {
          backgroundImage: `url(${CSS.escape(this.profile.picture)})`,
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    $route() {
      this.close_menu();
    },
  },
  methods: {
    open_menu() {
      console.log("OPEN");
      this.menu_visible = true;
    },
    close_menu() {
      console.log("CLOSE");
      this.menu_visible = false;
    },
  },
  components: {
    Unknown,
    LoadingBlock,
    Times,
    Bars,
  },
};
</script>

<style>
html,
body {
  height: 100%;
}

.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}
</style>
