const nav = [
  {
    name: "Home",
    text: "Home",
  },
  {
    name: "CreateSection",
    text: "Create Section",
  },
];

export default nav;
