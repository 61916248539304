<template lang="pug">
#content-home
  LoadingBlock(v-if="loading")
  template(v-else="")
    SmallSection(
      v-for="section in section_list"
      :key_id="section.key"
      :section_name="section.section_name"
      :children="section.children"
    )
    .p-2
      router-link.rounded-xl.bg-primary-500.p-2.flex.justify-center(:to="{name: 'CreateSection'}")
        Plus
</template>

<script>
import LoadingBlock from "../components/block/LoadingBlock";
import apiUrl from "../apiUrl";
import Plus from "../components/icon/Plus";
import SmallSection from "../components/SmallSection";

export default {
  name: "Home",
  data() {
    return {
      loading: true,
      section_list: [],
    };
  },
  created() {
    this.axios.get(apiUrl.section).then(
      ({
        data: {
          data: { section_body, section_list },
        },
      }) => {
        this.loading = false;
        this.section_list = section_list.map((section) => {
          section.children = section.body.map((key) => section_body[key]);
          return section;
        });
      }
    );
  },
  components: {
    SmallSection,
    Plus,
    LoadingBlock,
  },
};
</script>
