<template lang="pug">
svg.fill-current.h-12.w-12(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20")
  circle(cx="15.303" cy="15.303" r="0.312")
  circle(cx="10.000" cy="17.500" r="0.625")
  circle(cx="4.697" cy="15.303" r="0.938")
  circle(cx="2.500" cy="10.000" r="1.250")
  circle(cx="4.697" cy="4.697" r="1.562")
  circle(cx="10.000" cy="2.500" r="1.875")
  circle(cx="15.303" cy="4.697" r="2.188")
  circle(cx="17.500" cy="10.000" r="2.500")
</template>

<style scoped>
svg {
  animation: rotate 1s linear 0s infinite normal;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<script>
export default {
  props: {
    path: String,
  },
};
</script>
